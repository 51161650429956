import React, { useRef, useEffect } from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Page } from "../components";

const PauseButton = styled(motion.button)`
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 999999999;
  top: 30px;
  right: 100px;
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 900ms ease;
  cursor: pointer;
  border: none;

  :after,
  :before {
    content: "";
    width: 2px;
    height: 30px;
    background: white;
    display: flex;
    flex-flow: row wrap;
    transform: rotate(45deg);
    transform-origin: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  :after {
    transform: rotate(-45deg);
  }
`;

const VideoContainer = styled(motion.div)`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

const VideoPlayer = styled.video`
  width: 100%;
  height: 100vh;
  object-fit: cover;
`;

export default () => {
  const videoUrl = `https://videos.ctfassets.net/hos2x6mwmo8b/5Vtb6WfotrG50XyYST93Wb/c343e01a9800963a0b15edfeab2a6459/WhatsApp_Video_2021-03-15_at_17.06.15.mp4`;
  const videoRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    }, 1000);
  }, [videoRef]);

  return (
    <Page
      title="Manifesto"
      description="Página manifesto do site Lab721"
      removeFooter
    >
      <VideoContainer
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ type: "spring", bounce: 0, delay: 0.8 }}
      >
        <VideoPlayer
          ref={videoRef}
          width="100%"
          height="100%"
          autoPlay
          controls
        >
          <source data-lazy-src={videoUrl} type="video/mp4" src={videoUrl} />
        </VideoPlayer>
      </VideoContainer>
    </Page>
  );
};
